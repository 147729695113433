<template>
    <div class="putInto">
        <van-tabs v-model="active" color="#3F7C53" title-active-color="#3F7C53" @click="changeTab">
            <van-tab v-for="(item,idx)  in tabList" :key="idx" :title="item"></van-tab>
        </van-tabs>
        <div v-if="active===0">
            <van-field
                    readonly
                    input-align="right"
                    name="picker"
                    :value="deptNoTxt"
                    label="运营部"
                    right-icon="arrow"
                    placeholder="点击选择"
                    @click="showDeptPicker = true"
            />
            <van-popup v-model="showDeptPicker" position="bottom">
                <van-cascader
                        title="请选择运营部"
                        :options="deptList"
                        @close="showDeptPicker = false"
                        active-color="#3f7c53"
                        :field-names="deptNames"
                        @finish="deptChange"
                />
            </van-popup>
            <van-cell title="检查月份"  :value="checkDate" @click="showcheckDate=true"/>
            <van-popup v-model="showcheckDate" round position="bottom">
                <van-picker title="选择类型" show-toolbar
                            :columns="dateList"
                            @cancel="showcheckDate = false"
                            @confirm="changeCheckDateName"
                />
            </van-popup>
            <van-cell title="检查类型"  :value="typeText" @click="showState=true"/>
            <van-popup v-model="showState" round position="bottom">
                <van-picker title="选择类型" show-toolbar
                            :columns="typeList"
                            @cancel="showState = false"
                            @confirm="changeStateName"
                />
            </van-popup>
            <van-cell>
                <van-checkbox v-model="onlychecked" checked-color="#3F7C53">只查不规范</van-checkbox>
            </van-cell>
            <van-field  label="运营人员" v-model="checkSearch.userName" placeholder="请输入" />
            <div class="button-box">
                <van-button type="primary"   class="m-l-15" color="#3F7C53" size="small" @click="stateOnSearch">查 询</van-button>
            </div>


            <van-pull-refresh v-model="isLoadingState"
                              @refresh="stockOnRefresh">
                <div class="public-flex public-just-between m-t-15">
                    <div class="state-title">  检查数：{{checkSum}} ,问题数：{{questionSum}} , 考核分：{{score}}</div>
                </div>
                <van-list
                        v-model="stateLoading"
                        :finished="stateFinished"
                        finished-text="没有更多了"
                        @load="stateOnLoad"
                >
                    <div class="public-card state-padding m-b-15"
                         v-for="(item,idx) in checkDetail" :key="idx"
                    >
                        <div class="public-flex public-just-between m-t-15">
                            <div class="state-title">{{item.deptName}}-{{item.marketerName}}</div>
                            <div class="state-confim m-t-15"
                                 style="border-left:1px solid #F0F2F5"
                                 @click="questionDetail(item.id)"
                                 v-if="item.questionNum>0"
                            >问题处理</div>
                            </div>
                        <div class="public-flex public-just-between m-t-15">
                            <div class="state-info">
                                <div>类型: {{item.checkTypeName}}</div>
                                <div class="m-t-10">检查人: {{item.inspector}}</div>
                                <div class="m-t-10">检查时间: {{item.checkTime}}</div>
                                <div class="m-t-10" v-if="item.checkType==1">站点: {{item.stationName}}</div>
                                <div class="m-t-10" v-if="item.instructions!=null&&item.instructions!=''">其他情况: {{item.instructions}}</div>
                            </div>
                            <div  class="state-num" >{{item.questionNum}}</div>
                        </div>
                    </div>
                </van-list>

            </van-pull-refresh>

            <van-dialog v-model="questionListShow" title="问题列表"
                        :showConfirmButton="false"
                        :closeOnClickOverlay="true"
                        style="height:650px;overflow-y:auto"
            >
                <van-list
                        v-model="stateLoading"
                        :finished="stateFinished"
                >
                    <div class=" list-padding"
                         v-for="(item,idx) in dataList" :key="idx"
                    >

                        <div class="state-title">规范：{{item.checkName}}</div>

                        <div class="state-info">
                            <div>问题描述: {{item.question}}</div>
                            <div>整改意见: {{item.advice}}</div>
                            <div>整改期限: {{item.deadline}}天</div>
                            <div v-if="item.checkFile">检查图片: </div>
                            <van-uploader
                                          v-model="item.checkFile"
                                          v-if="item.checkFile"
                                          disabled
                                          :deletable="false"
                                          accept="image"
                                          :show-upload = false
                            />
                            <div v-if="item.masterPoints">站长扣分: {{item.masterPoints}}分</div>
                        </div>

                        <div class="qc-left">
                            <van-radio-group checked-color="#3F7C53"
                                             class="radiogroup"
                                             direction="horizontal"
                                             :disabled="(item.approve=='NOAPPROVE'||item.approve=='AGREE')"
                                             v-model="item.feedbackType">
                                <van-radio name="1">整改反馈
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? checked : unchecked" />
                                    </template>
                                </van-radio>
                                <van-radio name="2">申诉
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? checked : unchecked" />
                                    </template>
                                </van-radio>
                            </van-radio-group>
                        </div>
                        <van-field v-if="item.feedbackType == '1'"
                                   v-model="item.feedback"
                                   label="整改反馈"
                                   placeholder="请输入整改情况"
                                   maxlength="200"
                                   class="state-info"
                                   :readonly="(item.approve=='NOAPPROVE'||item.approve=='AGREE')"
                        />
                        <van-field v-if="item.feedbackType == '2'"
                                   v-model="item.feedback"
                                   label="申诉意见"
                                   placeholder="请输入申诉意见"
                                   maxlength="200"
                                   class="state-info"
                                   :readonly="(item.approve=='NOAPPROVE'||item.approve=='AGREE')"
                        />
                        <div class="state-info" v-if="item.feedbackType">图片上传</div>
                        <div class="card-upload" v-if="item.feedbackType">
                            <van-uploader v-model="item.fileList"
                                          accept="image"
                                          :disabled="(item.approve=='NOAPPROVE'||item.approve=='AGREE')"
                                          :max-count="1"
                                          :after-read="afterRead(item)" />
                        </div>
                        <div class="state-info" v-if="item.approveName!=null">
                            <div>审批状态: {{item.approveName}}</div>
                            <div  v-if="item.approve=='REJECT'">审批意见: {{item.comment}}</div>
                        </div>
                    </div>
                </van-list>
                <div class="button-box m-b-15">
                    <van-button plain type="primary" color="#3F7C53" size="small" @click="questionCancel">关闭</van-button>
                    <van-button  type="primary" color="#3F7C53" v-if="showButton" :disabled="isdisabled" size="small" @click="saveFeedback">提交</van-button>

                </div>
            </van-dialog>
        </div>
        <div v-if="active===1">
            <ruleChecking> </ruleChecking>
        </div>
    </div>

</template>

<script>
    import {getCheckList,getQuestionList,isCheckManager,saveFeedback} from '@/utils/ruleChecking';
    import { getMyDeptTree } from "@/utils/api";
    import ruleChecking from './ruleChecking';
    import common from '@/utils/common';
    import moment from "moment";
    import JsZip from "jszip";
    import {uploadZipAndAlreadyImg} from "../../utils/api";
    export default {
        name: "checkList.vue",
        components: {
            ruleChecking
        },
        data(){
            return{
                active: 0,
                isdisabled:false,
                show:false,
                showButton:true,
                onlychecked:false,
                minDate: new Date(2023,1,1),
                startDate:'',//开始日期 yyyy-MM-dd
                endDate:'',//结束日期 yyyy-MM-dd
                date:'',
                fileList:[],
                uploadZipImgList:[],
                fileImgUrls:[],
                defaultDate:[new Date((new Date()  - 1000 * 60 * 60 * 24 * 60)),new Date()] ,
                uniqueNo: common.getItem('uniqueNo')?common.getItem('uniqueNo'):this.$route.query.uniqueNo,
                checkSearch:{
                    userName:'',//运营人员姓名
                    checkDate:moment().format('YYYY/MM'),
                    checkType:'1',//检查类型
                    deptNo:'',
                },
                tabList: [
                    '检查结果',
                    '规范检查'
                ],
                pageIndex:1,//页码
                pageSize:10,
                typeText:'站点规范',
                checkDate:moment().format('YYYY/MM'),
                questionListShow:false,
                showState:false,
                deptNoTxt:'',
                questionSum:0,
                checkSum:0,
                score:0,
                checked: require('@/assets/img/site/checked.png'),
                unchecked: require('@/assets/img/site/unchecked.png'),
                showcheckDate:false,
                stateLoading:false,
                stateFinished:false,
                checkDetail:[],
                deptList:[],
                dataList:[],
                isLoadingState:false,
                showDeptPicker: false,
                dateList:[
                    {text:moment().format('YYYY/MM'),value: moment().format('YYYY/MM')},
                    {text:moment().subtract(1,'months').format('YYYY/MM'),
                        value:moment().subtract(1,'months').format('YYYY/MM')},
                    {text:moment().subtract(2,'months').format('YYYY/MM'),
                        value:moment().subtract(2,'months').format('YYYY/MM')},
                    {text:moment().subtract(3,'months').format('YYYY/MM'),
                        value:moment().subtract(3,'months').format('YYYY/MM')},
                ],
                deptNames:{
                    text: 'deptName',
                    value: 'deptNo',
                    children: 'child',
                },
                typeList:[
                    {
                        text:'站点规范',
                        value:'1'
                    },
                    {
                        text:'运营规范',
                        value:'2'
                    }
                ],

            }
        },
        mounted() {
            this.isCheckManager();
        },
        created(){
            this.getDeptList();
            //获取当前时间
            let myDate = new Date();
            let startY = myDate.getFullYear();
            let startM = myDate.getMonth() + 1;
            let startD = myDate.getDate();
            let myArr = startY +'-'+ (startM < 10 ? '0' + startM :startM) +'-'+(startD< 10 ? '0'+ startD : startD);
            //获取三十天前日期
            let lw = new Date(myDate - 1000 * 60 * 60 * 24 * 60);//最后一个数字30可改，30天的意思
            let lastY = lw.getFullYear();
            let lastM = lw.getMonth() + 1;
            let lastD = lw.getDate();
            let startdate = lastY +'-'+ (lastM < 10 ? '0' + lastM : lastM) +'-'+(lastD < 10 ? '0'+ lastD : lastD);
            this.date = startdate+'至'+ myArr;
            this.startDate = startdate;
            this.endDate = myArr;
        },
        methods:{
            saveFeedback(){
                console.log("dataList",this.dataList)
                var count=0;
                for (let obj of this.dataList) {
                    console.log("obj",obj)
                    if (!obj.feedbackType) {
                        count=count+1;
                    }
                    if(obj.feedbackType=='1'&&!obj.feedback){
                        this.$toast('请填写整改完成情况');
                        this.isdisabled=false;
                        return;
                    }
                    if(obj.feedbackType=='2'&&!obj.feedback){
                        this.$toast('请填写申诉意见');
                        this.isdisabled=false;
                        return;
                    }
                    this.isdisabled=true;
                    console.log('obj.fileList:',obj.fileList)
                    if(obj.fileList){
                        console.log('有图片上传')
                        var list = obj.fileList[0];
                        console.log('list:',list)
                        let self = this;
                        let imgName = 'checkFeedBack'
                        var zip = new JsZip();
                        var img = zip.folder("images");
                       // console.log("mingzi:",obj.fileList[0].name)
                        for (var i = 0; i < list.length; i++) {
                            img.file(imgName + '_' + i + list[i].name, list[i].data.substring(list[i].data.indexOf(",") + 1), {base64: true});
                        }
                        let json = {}
                        json[imgName] = self.fileImgUrls
                        let fileAleradyJson = JSON.stringify(json)
                        console.log("提交的网络图片：",json)
                        zip.generateAsync({type: "blob"}).then(function (content) {
                            uploadZipAndAlreadyImg(content, obj.id, fileAleradyJson).then(res => {
                                if (res.code == 200 && (res.data.businessCode === 200)) {
                                    console.log("返回：",res.data)
                                    let arr = res.data.surveyInputImg
                                    self.fileImgUrls = arr
                                    //self.saveAction()
                                }
                            }).catch(() => {
                            })

                        }, e => {
                            this.$toast('上传图片异常')
                        });
                    }

                }
                if(this.dataList.length==count){
                    this.$toast('请填写信息提交');
                    this.isdisabled=false;
                    return;
                }
                saveFeedback({dataList:this.dataList,token: common.getItem('wtToken')}).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data.businessCode == 200) {
                            this.$toast("提交成功");
                            this.isdisabled=false;
                            this.questionListShow = false;
                        }
                    }
                })
            },
            isCheckManager(){
                isCheckManager({uniqueNo:this.uniqueNo}).then(res=>{
                    console.log("list,res:",res)
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.isManager = res.data.data;
                    }
                })
            },
            deptChange(value){
                this.deptNoTxt = value.selectedOptions.map((option) => option.deptName).join('/');
                this.checkSearch.deptNo = value.selectedOptions.length > 0 ? value.selectedOptions[value.selectedOptions.length-1].deptNo:'';
                this.showDeptPicker=false;

            },
            getDeptList(){
                getMyDeptTree({token: common.getItem('wtToken')}).then(res=>{
                    if (res.code ===200 && res.data.businessCode === 200) {
                        this.deptList = this.getTreeData(res.data.data);
                    }
                })
            },
            afterRead(item){
                return file=>{
                    // 大于15M
                    if (file.file.size > 15 * 1024 * 1024) {
                        this.$toast('图片太大，请上传小于15M的图片')
                        return
                    }
                    let reader = new FileReader()
                    reader.readAsDataURL(file.file)
                    reader.onload = function (e) {
                        let content = file.content //图片的src，base64格式
                        let img = new Image()
                        let namearr = file.file.name.split('.')
                        let name = '.' + namearr[namearr.length - 1]
                        img.src = content
                        img.onload = function () {
                            common.compress(img, (dataURL) => {
                                var imgs=item.fileList||[];
                                var i=imgs.length;
                                if(i<=0){
                                    imgs=[{data: dataURL, name: name}];
                                }else{
                                    imgs[i-1]=[{data: dataURL, name: name}];
                                }
                                item.fileList=imgs;
                                console.log("afterRead:",item.fileList)
                            })
                        }
                    }
                }
            },
            deletePic (item) {
                console.log("deletePic")
                //item.fileList=[];
                //item.fileList.splice(0,1)
            },
            afterRead1 (file, detail) {
                // 大于15M
                if (file.file.size > 15 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于15M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.fileList.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 100 * 1024) {
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    this.uploadZipImgList.push({ data: file.content, name: name })
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)
                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {
                        common.compress(img, (dataURL) => {
                            this.uploadZipImgList.push({ data: dataURL, name: name })

                        })
                    }
                }
            },
            deletePic1 (file, pic) {
                this.fileList.splice(pic.index,1)
                if (file.url){
                    this.fileImgUrls.splice(pic.index,1)
                }else {
                    this.uploadZipImgList.splice(pic.index - this.fileImgUrls.length,1)
                }

            },
            getTreeData(data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].child.length < 1) {
                        // children若为空数组，则将children设为undefined
                        data[i].child = undefined;
                    } else {
                        // children若不为空数组，则继续 递归调用 本方法
                        this.getTreeData(data[i].child);
                    }
                }
                return data;
            },
            questionCancel(){
                this.questionListShow = false;
            },
            onConfirm(date) {
                const [start, end] = date;
                this.show = false;
                this.date = `${this.formatDate(start)}至${this.formatDate(end)}`;
                this.startDate = this.formatDate(start);
                this.endDate = this.formatDate(end);
            },
            questionDetail(id) {
                let arr = [];
                let obj = {}
                obj.id = id;
                arr.push(obj);
                console.log('arr问题记录',arr);
                getQuestionList({transferGoodsList:arr}).then(res=>{
                    console.log('result',res);
                    if(res.code===200 && res.data.businessCode===200){
                        this.questionListShow=true;
                        this.dataList = res.data.data.dataList;
                        console.log("dataList:",this.dataList)
                        var count=0;
                        this.showButton=true;
                        for (let obj of this.dataList) {
                            if (obj.approve&&(obj.approve=='NOAPPROVE'||obj.approve=='AGREE')) {
                                count = count + 1;
                            }
                            if(obj.filePath){
                                console.log("obj.filePath:"+obj.filePath)
                                obj.fileList=[];
                                if(process.env.NODE_ENV == 'production'){
                                    let url = window.location.origin + '/file/' + obj.filePath + '?Authorization=' + common.getItem('wtToken')
                                    console.log("uel:",url)
                                    obj.fileList.push({url:url})
                                }else{
                                    let url = 'http://124.70.167.116:20081' + '/file/' +  obj.filePath + '?Authorization=' + common.getItem('wtToken')
                                    console.log("uel:",url)
                                    obj.fileList.push({url:url})
                                }
                                console.log("fileList:",obj.fileList)
                            }
                            if(obj.checkFilePath){
                                console.log("obj.checkFilePath:"+obj.checkFilePath)
                                obj.checkFile=[];
                                if(process.env.NODE_ENV == 'production'){
                                    let url = window.location.origin + '/file/' + obj.checkFilePath + '?Authorization=' + common.getItem('wtToken')
                                    console.log("uel:",url)
                                    obj.checkFile.push({url:url})
                                }else{
                                    let url = 'http://124.70.167.116:20081' + '/file/' +  obj.checkFilePath + '?Authorization=' + common.getItem('wtToken')
                                    console.log("uel:",url)
                                    obj.checkFile.push({url:url})
                                }
                                console.log("checkfile:",obj.checkFile)
                            }
                        }
                        console.log("dataList.length",this.dataList.length)
                        console.log("count",count)
                        if(this.dataList.length==count){
                            this.showButton=false;
                        }

                    }
                })
            },
            //切换专栏
            changeTab() {
                if (this.active === 1) {
                    console.log("this.isManager:",this.isManager)
                    if(this.isManager!="1"){
                        this.$message.warning('您暂无规范检查提交权限');
                        return;
                    }
                }
            },
            stateOnSearch(flag){
                if(flag){
                    this.checkList = []
                    this.pageIndex = 1
                }
                let data = {
                    //pageIndex:this.pageIndex,//页码
                    //pageSize:this.pageSize,
                    checkQuestion:this.onlychecked?1:'',
                    ...this.checkSearch,
                }
                getCheckList(data).then(res=>{
                    this.stateLoading = false;
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.stateFinished = !res.data.data.hasNextPage;
                        this.questionSum = res.data.data.questionSum;
                        this.checkSum = res.data.data.checkSum;
                        this.score=res.data.data.score;
                        this.checkDetail = res.data.data.checkDetail;
                        /*
                        if (this.pageIndex === 1) {
                            this.checkDetail = res.data.data.checkDetail;
                        } else {
                            this.checkDetail = this.checkDetail.concat(res.data.data.checkDetail);
                        }
                        if (!this.stateFinished) {
                            this.pageIndex++;
                        }
                         */
                    }
                })
            },
            stateOnLoad(){
                this.stateOnSearch();
            },
            changeStateName(value){
                this.typeText = value.text;
                this.checkSearch.checkType = value.value;
                this.showState = false;
            },
            changeCheckDateName(value){
                this.checkDate = value.text;
                this.checkSearch.checkDate = value.value;
                this.showcheckDate = false;
            },
            stockOnRefresh(){
                this.isLoadingState = false
                this.checkDetail = []
                this.pageIndex = 1
                this.stateOnSearch()
            }
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";
    .state-padding{
        padding: 18px 40px 15px 15px;
        position: relative;
        .state-title{
            font-size: 16px;
            font-weight: bold;
            color: #191F25;
        }
        .state-img{
            position: absolute;
            top:0;right: 0;
        }
        .state-info{
            font-size: 13px;
            font-weight: 500;
            color: #7E8185;
        }
        .state-num{
            font-size: 24px;
            font-weight: bold;
            color: #F25555;
        }
        .state-reject{
            width:50%;
            color:#F25555;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }
        .state-confim{
            width:50%;
            color:#3F7C53;
            text-align: right;
            font-size: 14px;
            font-weight: 300;
        }
        .line{
            border-top:1px solid #F0F2F5;
        }

    }

    .list-padding{
        padding: 18px 40px 15px 15px;
        position: relative;
        .state-title{
            font-size: 10px;
            font-weight: bold;
            color: #191F25;
        }
        .qc-left {
            width: 2.8rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 0.2rem;
        }
        .state-info{
            font-size: 9px;
            font-weight: 400;
            color: #7E8185;
        }
        .state-reject{
            width:50%;
            color:#F25555;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }
        .state-confim{
            width:50%;
            color:#3F7C53;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }

    }

    .card{
        margin:10px;
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 10px 10px 5px #F5F6FA;
    }

    .img-icon {
        width: 0.28rem;
        height: 0.28rem;


        &:nth-child(1) {
            margin-left: 0;
        }
    }
    .card-upload {
        // margin: 0 0.3rem;
        height: 1.6rem;
        background: #fff;
        display: flex;
        align-items: center;
        padding: 0 0.2rem;
        overflow: hidden;
    }


</style>
